import React from 'react';
import Routers from './routers';
import { Suspense } from 'react';

const App: React.FC = () => {
  return (
    <Suspense>
      <Routers />
    </Suspense>
  )
}

export default App;
