import { createTheme } from '@mui/material/styles';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';

const theme = createTheme({
    palette,
    breakpoints: breakpoints,
    typography: typography
});

export default theme